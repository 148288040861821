<template>
	<div>
		<div class="d-flex justify-space-between align-center py-2 px-4">
			<strong class="subtitle-2">团队列表</strong>
			<div class="d-flex align-center">
				<div style="max-width:180px;">
					<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
								color="primary"
								text
								small
								tile
								v-bind="attrs"
								v-on="on"
								>
								<v-icon small left>
									mdi-filter-variant
								</v-icon>
								显示: {{getStatusText}}
								<v-icon small>
									mdi-menu-down
								</v-icon>
								</v-btn>
							</template>
							<v-list flat dense>
								<v-list-item
									v-for="(item, index) in statusText"
									:key="index"
									@click="updateStatus(item.value)"
									>
								<v-list-item-subtitle class="body-2">{{ item.text }}</v-list-item-subtitle>
								</v-list-item>
							</v-list>
					</v-menu>
				</div>
				<v-divider vertical class="mx-3"></v-divider>
				<div style="max-width:150px;">
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
							color="primary"
							text
							small
							tile
							v-bind="attrs"
							v-on="on"
							>
								每页显示 {{itemsPerPage}}
							<v-icon small>
								mdi-menu-down
							</v-icon>
							</v-btn>
						</template>
						<v-list flat dense>
							<v-list-item
								v-for="(item, index) in items"
								:key="index"
								@click="itemsPerPage = parseInt(item, 10)"
								>
							<v-list-item-subtitle class="body-2">{{ item }}</v-list-item-subtitle>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</div>
		</div>
		<v-divider></v-divider>
		<template v-if="loading">
			<v-sheet class="d-flex flex-column justify-center align-center" height="60vh">
				<v-progress-circular
					:size="70"
					:width="3"
					color="primary"
					indeterminate
				></v-progress-circular>
				<div class="subtitle-1 mt-4 ">刷新数据中</div>
			</v-sheet>
		</template>
		<template v-else>
			<v-data-table
				:headers="headers"
				:items="teams"
				:items-per-page="itemsPerPage"
				:page.sync="currentPage"
				hide-default-footer
				@update:options="onUpdatePageOptions"
				>
				<template v-slot:item.type="{ item }">
					{{ item.type | getTypeLabel }}
				</template>
				<template v-slot:item.actions="{item}">
					<v-btn 
						color="primary"
						elevation="0"
						class="mr-2"
						small
						:to="{ name: 'viewTeam', params: {id:item.id}}"
					>
						<v-icon small left>
							mdi-eye
						</v-icon>
						查看信息
					</v-btn>
					<v-btn 
						:color="status ? 'success': 'info'"
						v-if="user && user.role === 'admin'"
						elevation="0"
						small
						@click="updateTeam(item.id)"
					>
					<template v-if="status">
						<v-icon small left>
							mdi-archive-arrow-up
						</v-icon>
							激活信息
					</template>
					<template v-else>
						<v-icon small left>
							mdi-archive-arrow-down
						</v-icon>
							归档信息
					</template>
					</v-btn>
				</template>
			</v-data-table>
			<v-divider></v-divider>
			<div class="d-flex justify-center py-2 ">
				<v-pagination v-model="currentPage" :length="totalPages" @input="onUpdatePage"></v-pagination>
			</div>
		</template>

		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import WorkTeamService from '../../services/WorkTeam';
import WorkTeamForm from '../../components/Works/WorkTeamForm';
import { mapState } from 'vuex';

export default {
	name: 'WorkTeamList',
	data: () => ({
		teams: [],
		itemsPerPage: 15,
		totalPages: 1,
		currentPage: 1,
		items: [10, 15, 20],
		status: 0,
		headers: [
			{ text: '编码', value: 'id',align: 'start', },
			{ text: '名称', value: 'name' },
			{ text: '类型', value: 'type' },
	        { text: '功能', value: 'actions', sortable: false, width: 240 }
		],
		teamData: null,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		loading: false,
		status: 0,
		statusText: [
			{ text: '激活数据', value: 0 },
			{ text: '归档数据', value: 1 },
		],
	}),
	components:{
		WorkTeamForm
	},
	props: {
	},
	watch: {
		loading(){
			if(this.loading){
				setTimeout( ()=>{
					this.loading = false;
				}, 1000)
			}
		}
	},
	filters: {
		getTypeLabel(value){
			return value === "team" ? "团队" : "个人";
		}
	},
	computed: {
		...mapState('user', ['user'],),
		getStatusText(){
			let returnText;
			this.statusText.forEach(status=>{
				if(status.value === this.status){
					returnText = status.text;
				}
			})
			return returnText;
		}
	},
	methods: {
		async getTeams(){
			try {
				let params = {
					"archived": this.status,
					"page": this.currentPage,
					"per_page": this.itemsPerPage
				};

				let response = await WorkTeamService.getWorkTeamList(params)
				this.teams = response.data.data.items;
				this.itemsPerPage = response.data.data.max_items_per_page;
				this.totalPages = response.data.data.total_pages;
				this.currentPage = response.data.data.current_page;

			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}
		},
		onUpdatePageOptions(option){
			this.itemsPerPage = option.itemsPerPage;
			this.currentPage = option.page;
			this.getTeams();
		},
		onUpdatePage(page){
			this.currentPage = page;
			this.getTeams();
		},
		async updateTeam(team_id){
			try {
				this.loading = true;
				let body = {
					"archived": this.status ? false : true
				}
				let response = await WorkTeamService.updateWorkTeam(team_id, body);

				if(response.data.statusCode === 200){
					this.snackbar.message = this.status ? `团队激活成功` : `团队归档成功`;
					this.snackbar.color = 'success';
					this.snackbar.model = true;
					setTimeout(()=>{
						this.snackbar.model = false;
						this.getTeams();
					}, 1000)
				}
			} catch(error){
				this.snackbar.message = `错误信息：${error.data.message}`;
				this.snackbar.color = 'error';
				this.snackbar.model = true;
			}
		},
		updateStatus(event){
			this.status = event;
			this.getTeams();
		},
	}
}
</script>
