<template>
	<div>
		<div class="d-flex justify-space-between align-center py-2 px-4">
			<strong class="subtitle-2">{{getStatusText}}信息列表</strong>
		<div class="d-flex justify-end py-2 pr-4">
				<!--- worker filter --->
				<div style="max-width:150px;">
					<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
								color="primary"
								text
								small
								tile
								v-bind="attrs"
								v-on="on"
								>
								<v-icon small left>
									mdi-filter-variant
								</v-icon>
								显示: {{getStatusText}}
								<v-icon small>
									mdi-menu-down
								</v-icon>
								</v-btn>
							</template>
							<v-list flat dense>
								<v-list-item
									v-for="(item, index) in statusText"
									:key="index"
									@click="updateStatus(item.value)"
									>
								<v-list-item-subtitle class="body-2">{{ item.text }}</v-list-item-subtitle>
								</v-list-item>
							</v-list>
					</v-menu>
				</div>
				<v-divider vertical class="mx-3"></v-divider>
				<!--- page selector  --->
				<div style="max-width:150px;">
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
							color="primary"
							text
							small
							tile
							v-bind="attrs"
							v-on="on"
							>
								每页显示 {{itemsPerPage}}
							<v-icon small>
								mdi-menu-down
							</v-icon>
							</v-btn>
						</template>
						<v-list flat dense>
							<v-list-item
								v-for="(item, index) in items"
								:key="index"
								@click="itemsPerPage = parseInt(item, 10)"
								>
							<v-list-item-subtitle class="body-2">{{ item }}</v-list-item-subtitle>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</div>
		</div>
		<v-divider></v-divider>		
		<v-data-table
			:headers="headers"
			:items="workers"
			:items-per-page="itemsPerPage"
			:page.sync="currentPage"
			hide-default-footer
			@update:options="onUpdatePageOptions"
			>
			<!-- @click="viewWorker(item.id)" -->
			<template v-slot:item.actions="{item}">
				<v-btn 
					color="primary"
					elevation="0"
					class="mr-2"
					small
					:to="{ name: 'viewWorker', params: {id:item.id}}"
				>
					<v-icon small left>
						mdi-eye
					</v-icon>
					查看信息
				</v-btn>
				<v-btn 
					:color="status ? 'success': 'info'"
					elevation="0"
					small
					v-if="user && user.role === 'admin'"
					@click="updateWorkerStatus(item.id)"
					>
					<template v-if="status">
						<v-icon small left>
							mdi-archive-arrow-up
						</v-icon>
							激活信息
					</template>
					<template v-else>
						<v-icon small left>
							mdi-archive-arrow-down
						</v-icon>
							归档信息
					</template>
					</v-btn>
			</template>
		</v-data-table>
		<v-divider></v-divider>
		<div class="d-flex justify-center py-2 ">
			<v-pagination v-model="currentPage" :length="totalPages" @input="onUpdatePage"></v-pagination>
		</div>
		<!--- 编辑工人信息 ---->
		<v-dialog v-model="dialogWorker" 
			max-width="480">
				<worker-form 
					:workerData="workerData"
					@on-submit-success="onWorkFormSubmit"
				/>
		</v-dialog>

		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>

import WorkerService from '../../services/Worker';
import WorkerForm from '../Works/WokerFrom';
import { mapState } from 'vuex';

export default {
	name: 'WorkerList',
	components: {
		WorkerForm
	},
	data: () => ({
		workers: [],
		itemsPerPage: 15,
		totalPages: 1,
		currentPage: 1,
		items: [10, 15, 20],
		status: 0,
		headers: [
			{ text: '编码', value: 'id',align: 'start', },
			{ text: '姓名', value: 'name' },
			{ text: '出生日期', value: 'date_of_birth', sortable: false, },
			{ text: '工人开始日期', value: 'start_date', sortable: false, },
			{ text: '工人结束日期', value: 'end_date', sortable: false, },
	        { text: '功能', value: 'actions', sortable: false, width: 260 }
		],
		dialogWorker: false,
		workerData: null,
		statusText: [
			{ text: '激活信息', value: 0 },
			{ text: '归档信息', value: 1 },
		],
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
	}),
	props: {
		reload: {
			type: Boolean,
			default: false
		}
	},
	watch: {

	},
	computed: {
		...mapState('user', ['user'],),
		getStatusText(){
			let returnText;
			this.statusText.forEach(status=>{
				if(status.value === this.status){
					returnText = status.text;
				}
			})
			return returnText;
		}
	},
	created(){
	},
	methods: {
		async getWorkers(){
			try {
				let response = await WorkerService.getWorkerList(this.currentPage, this.itemsPerPage, this.status)
				this.workers = response.data.data.items;
				this.itemsPerPage = response.data.data.max_items_per_page;
				this.totalPages = response.data.data.total_pages;
				this.currentPage = response.data.data.current_page;
			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}
		},
		async viewWorker(worker_id){
			this.workerData = null;
			this.dialogViewWorker = true;
			try {
				let response = await WorkerService.getWorker(worker_id);
				this.workerData = response.data.data;
			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}
		},
		async updateWorkerStatus(worker_id){
			try {
				let body = {
					"archived": this.status ? false : true
				}
				let response = await WorkerService.updateWorker(worker_id, body);

				if(response.data.statusCode === 200){
					this.snackbar.message = `工人${response.data.message}`;
					this.snackbar.color = 'success';
					this.overlay = false;
					this.snackbar.model = true;
					setTimeout(()=>{
						this.snackbar.model = false;
						this.getWorkers();
					}, 1000)
				}
			} catch(error){
				this.snackbar.message = `错误信息：${error.data.message}`;
				this.snackbar.color = 'error';
				this.overlay = false;
				this.snackbar.model = true;
			}
		},
		onUpdatePageOptions(option){
			this.itemsPerPage = option.itemsPerPage;
			this.currentPage = option.page;
			this.getWorkers();
		},
		onUpdatePage(page){
			this.currentPage = page;
			this.getWorkers();
		},
		onEditWorker(){
			this.dialogWorker = true;
		},
		onWorkFormSubmit(){
			this.dialogWorker = false;
			this.workerData = null;
			this.getWorkers();
		},
		updateStatus(event){
			this.status = event;
			this.currentPage = 1;
			this.getWorkers();
		},
	}
}
</script>
