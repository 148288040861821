var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center py-2 px-4"},[_c('strong',{staticClass:"subtitle-2"},[_vm._v("团队列表")]),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticStyle:{"max-width":"180px"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":"","small":"","tile":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-filter-variant ")]),_vm._v(" 显示: "+_vm._s(_vm.getStatusText)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',{attrs:{"flat":"","dense":""}},_vm._l((_vm.statusText),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateStatus(item.value)}}},[_c('v-list-item-subtitle',{staticClass:"body-2"},[_vm._v(_vm._s(item.text))])],1)}),1)],1)],1),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('div',{staticStyle:{"max-width":"150px"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":"","small":"","tile":""}},'v-btn',attrs,false),on),[_vm._v(" 每页显示 "+_vm._s(_vm.itemsPerPage)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',{attrs:{"flat":"","dense":""}},_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.itemsPerPage = parseInt(item, 10)}}},[_c('v-list-item-subtitle',{staticClass:"body-2"},[_vm._v(_vm._s(item))])],1)}),1)],1)],1)],1)]),_c('v-divider'),(_vm.loading)?[_c('v-sheet',{staticClass:"d-flex flex-column justify-center align-center",attrs:{"height":"60vh"}},[_c('v-progress-circular',{attrs:{"size":70,"width":3,"color":"primary","indeterminate":""}}),_c('div',{staticClass:"subtitle-1 mt-4 "},[_vm._v("刷新数据中")])],1)]:[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.teams,"items-per-page":_vm.itemsPerPage,"page":_vm.currentPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.currentPage=$event},"update:options":_vm.onUpdatePageOptions},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getTypeLabel")(item.type))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","elevation":"0","small":"","to":{ name: 'viewTeam', params: {id:item.id}}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-eye ")]),_vm._v(" 查看信息 ")],1),(_vm.user && _vm.user.role === 'admin')?_c('v-btn',{attrs:{"color":_vm.status ? 'success': 'info',"elevation":"0","small":""},on:{"click":function($event){return _vm.updateTeam(item.id)}}},[(_vm.status)?[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-archive-arrow-up ")]),_vm._v(" 激活信息 ")]:[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-archive-arrow-down ")]),_vm._v(" 归档信息 ")]],2):_vm._e()]}}])}),_c('v-divider'),_c('div',{staticClass:"d-flex justify-center py-2 "},[_c('v-pagination',{attrs:{"length":_vm.totalPages},on:{"input":_vm.onUpdatePage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],_c('v-snackbar',{attrs:{"absolute":"","top":"","color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.model = false}}},'v-btn',attrs,false),[_vm._v(" 关闭 ")])]}}]),model:{value:(_vm.snackbar.model),callback:function ($$v) {_vm.$set(_vm.snackbar, "model", $$v)},expression:"snackbar.model"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }