<template>
<div>
	<v-card>
		<v-card-title>
			<template v-if="isUpdate">
				编辑工种信息
			</template>
			<template v-else>
				添加工种信息
			</template>
		</v-card-title>
		<v-divider></v-divider>
		<div class="pa-3">
			<v-form
				ref="form"
				class="pa-3"
				v-model="valid"
				lazy-validation
				@submit.prevent="onFormSubmit"
			>
				<v-text-field
					v-model="name"
					:counter="2"
					outlined
					:rules="nameRules"
					label="工种"
					required
				></v-text-field>
				<div class="d-flex justify-space-between">
					<v-btn
						color="error"
						text
						@click="reset"
						>
						重新填写
					</v-btn>
					<v-btn
						:disabled="!valid"
						color="success"
						elevation="0"
						@click="validate"
						type="submit"
					>
						{{submitText}}
					</v-btn>
				</div>
			</v-form>
		</div>
	</v-card>
		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
		<!--- loading overlay ---->
		<v-overlay :value="overlay">
			<v-progress-circular
			:size="70"
			:width="7"
			color="primary"
			indeterminate
			></v-progress-circular>
		</v-overlay>
		</div>
</template>

<script>

import WorkTypeService from '../../services/WorkType';

export default {
	name: 'userForm',
	data: () => ({
		valid: true,
		name: '',
		nameRules: [
			v => !!v || '请输入工种名称',
			v => (v && v.length >= 2) || '项目名称至少2个字',
		],
		requiredRules: [
			v => !!v || '必填内容',
		],
		type_id: null,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		overlay: false,
		isUpdate: false
	}),
	props: {
		workType: {
			type: Object,
			required: false,
		},
	},
	watch: {
		workType(){
			this.workTypeDataUpdate();
		}
	},
	computed: {
		submitText(){
			return this.isUpdate ? '更新工种' : '添加工种';
		}
	},
	created(){
		this.workTypeDataUpdate();
	},
	methods: {
		validate () {
			this.$refs.form.validate();
		},
		reset () {
			this.$refs.form.reset()
		},
		resetValidation () {
			this.$refs.form.resetValidation()
		},
		workTypeDataUpdate(){
			if(this.workType != null){
				this.name = this.workType.name;
				this.type_id = this.workType.id;
				this.isUpdate = true;
			}
		},
		onFormSubmit(){
			this.overlay = true;
			//create paylod body here
			const body = {
				"name": this.name
			}

			if(this.isUpdate){
				this.updateWorkType(body);
			} else {
				this.createWorkType(body);
			}
		},
		async updateWorkType(body){
			try {
				let response = await WorkTypeService.updateWorkType(this.type_id, body);

				if(response.data.statusCode === 200){
					this.snackbar.message = `工种${response.data.message}`;
					this.snackbar.color = 'success';
					this.overlay = false;
					this.snackbar.model = true;
					setTimeout(()=>{
						this.snackbar.model = false;
						this.$emit('on-submit-success');
					}, 1000)
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					this.overlay = false;
					this.snackbar.model = true;
			}
		},
		async createWorkType(body){
			try {
				let response = await WorkTypeService.careateWorkType(body);

				if(response.data.statusCode === 200){
					this.snackbar.message = `工种${response.data.message}`;
					this.snackbar.color = 'success';
					this.overlay = false;
					this.snackbar.model = true;
					this.reset();
					setTimeout(()=>{
						this.snackbar.model = false;
						this.reset();
						this.$emit('on-submit-success');
					}, 1000)
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					this.overlay = false;
					this.snackbar.model = true;
			}
		}
	}
}
</script>
