<template>
	<v-sheet>
		<v-toolbar flat>
			<v-toolbar-title>工作组管理</v-toolbar-title>
			<v-spacer></v-spacer>
			<template v-if="tab === 0">
				<v-btn 
					v-if="user && user.role === 'admin'"
					color="primary"
					@click="dialogWorkTeam = true"
					elevation="0">
					<v-icon>mdi-plus</v-icon> 添加工作组
				</v-btn>
			</template>
			<template v-if="tab === 1">
				<v-btn 
					v-if="user && user.role === 'admin'"
					color="primary"
					@click="dialogWorker = true"
					elevation="0">
					<v-icon>mdi-plus</v-icon> 添加工人
				</v-btn>
			</template>
			<template v-if="tab === 2">
				<v-btn 
					v-if="user && user.role === 'admin'"
					color="primary"
					@click="dialogWorkType = true"
					elevation="0">
					<v-icon>mdi-plus</v-icon> 添加工种
				</v-btn>
			</template>
		</v-toolbar>
		<v-divider></v-divider>
		<v-tabs v-model="tab" @change="loading = true">
			<v-tab v-for="tab in items" :key="tab.name">{{tab.name}}</v-tab>
		</v-tabs>
		<v-divider></v-divider>
		<template v-if="loading">
			<v-sheet class="d-flex flex-column justify-center align-center" height="60vh">
				<v-progress-circular
					:size="70"
					:width="3"
					color="primary"
					indeterminate
				></v-progress-circular>
				<div class="subtitle-1 mt-4 ">刷新数据中</div>
			</v-sheet>
		</template>
		<template v-else>
			<template v-if="tab === 0">
				<work-team-list />
				<v-dialog v-model="dialogWorkTeam"
					max-width="480">
					<work-team-form 
					@on-submit-success="onWorkTeamCreated" />
				</v-dialog>
			</template>
			<template v-if="tab === 1">
				<worker-list />
				<!--- create worker Dialog ---->
				<v-dialog v-model="dialogWorker" 
					max-width="480">
						<worker-form 
						@on-submit-success="onWorkerCreated" />
				</v-dialog>
			</template>
			<template v-if="tab === 2">
				<work-type-list />
				<!--- create work type Dialog ---->
				<v-dialog v-model="dialogWorkType" 
					max-width="480">
						<work-type-form 
						 @on-submit-success="onWorkTypeCreated" />
				</v-dialog>
			</template>
		</template>
	</v-sheet>
</template>

<script>
import workerList from '../../components/Works/WorkerList';
import workTeamList from '../../components/Works/WorkTeamList';
import WorkTypeList from '../../components/Works/WorkTypeList';
import WorkerForm from '../../components/Works/WokerFrom';
import WorkTypeForm from '../../components/Works/WorkTypeForm';
import WorkTeamForm from '../../components/Works/WorkTeamForm';

import { mapState } from 'vuex';
export default {
	name: 'Team',
	components: {
		workerList,
		workTeamList,
		WorkTypeList,
		WorkerForm,
		WorkTypeForm,
		WorkTeamForm
	},
	computed: {
		...mapState('user', ['user'],),
	},
	watch: {
		loading(){
			if(this.loading){
				setTimeout( ()=>{
					this.loading = false;
				}, 1000)
			}
		}
	},
	data: () => ({
		tab: 0,
		currentUser: null,
		items: [
			{'name': '工作团队' },
			{'name': '工人信息' },
			{'name': '工种管理' }
		],
		dialogWorker: false,
		dialogWorkType: false,
		dialogWorkTeam: false,
		loading: false
	}),
	methods: {
		onWorkTypeCreated(){
			this.dialogWorkType = false;
			this.loading = true;
		},
		onWorkerCreated(){
			this.dialogWorker = false;
			this.loading = true;
		},
		onWorkTeamCreated(){
			this.dialogWorkTeam = false;
			this.loading = true;
		}
	},
	created(){
		this.loading = true;
	}
}
</script>
