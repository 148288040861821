<template>
<div>
	<v-card>
		<v-card-title>
			<template v-if="teamId">
				编辑团队信息
			</template>
			<template v-else>
				添加团队信息
			</template>
		</v-card-title>
		<v-divider></v-divider>
		<div class="pa-3">
			<v-form
				ref="form"
				class="pa-3"
				v-model="valid"
				lazy-validation
				@submit.prevent="onFormSubmit"
			>
				<v-text-field
					v-model="name"
					:counter="2"
					outlined
					:rules="nameRules"
					label="团队名称"
					required
				></v-text-field>
				<v-select
					outlined
					v-model="teamType"
					:items="teamTypes"
					item-text="text"
          			item-value="value"
					label="团队类型"
				></v-select>
				<template v-if="teamId">
					<div class="d-flex py-2 px-1 justify-space-between align-center">
						<div class="subtitle-2">团队成员</div>
						<v-btn
							color="success"
							text
							small
							elevation="0"
							@click="assignWorkersToTeam"
						>
							<v-icon left small>mdi-plus</v-icon>
							添加工人
						</v-btn>
					</div>
					<!--- 已有项目工人 --->
					<v-divider></v-divider>
					<template v-if="workers && workers.length">
					<v-list flat dense class="pa-0">
						<v-list-item-group v-for="worker in workers" :key="worker.id">
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>{{worker.name}}</v-list-item-title>
								</v-list-item-content>
								<v-list-item-action>
									<v-btn
										small
										icon
										color="error"
										@click="removeWorker(worker)"
									>
										<v-icon small>mdi-trash-can-outline</v-icon>
									</v-btn>
								</v-list-item-action>
							</v-list-item>
							<v-divider></v-divider>
						</v-list-item-group>
					</v-list>
					</template>
					<template v-else>
						<v-alert
							class="ma-3"
							text
							dense
							type="info"
							>
								<div class="body-2">当前项目还没有添加工人</div>
						</v-alert>
					<v-divider></v-divider>
					</template>
				</template>
				<div class="d-flex justify-space-between mt-4">
					<template v-if="teamId">
					</template>
					<template v-else>
						<v-btn
							color="error"
							text
							@click="reset"
							>
							重新填写
						</v-btn>
					</template>
					<v-btn
						:disabled="!valid"
						color="success"
						elevation="0"
						@click="validate"
						type="submit"
					>
						{{submitText}}
					</v-btn>
				</div>
			</v-form>
			<!--- Assign worker to team dialog --->
			<v-dialog
				v-model="assignWorker"
				max-width="360"
			>
				<v-card>
					<v-card-title>选择想要添加的工人</v-card-title>
					<v-divider></v-divider>
					<v-list flat dense class="pa-0">
						<v-list-item-group v-for="worker in availableWorkers" :key="worker.id">
							<v-list-item :disabled="isWorkerDisabled(worker.id)">
								<v-list-item-content>
									<v-list-item-title class="pl-2">{{worker.name}}</v-list-item-title>
								</v-list-item-content>
								<v-list-item-action>
									<v-btn small text color="success" :disabled="isWorkerDisabled(worker.id)" @click.stop="assignThisWorker(worker)">
										添加
									</v-btn>
								</v-list-item-action>
							</v-list-item>
							<v-divider></v-divider>
						</v-list-item-group>
					</v-list>
					<v-card-actions>
						<template v-if="totalPages > 1">
							<v-btn
								text
								@click="previousPage"
								:disabled="prevPageBtnState"
							>上一页</v-btn>
							<v-btn
								text
								@click="nextPage"
								:disabled="nextPageBtnState"
							>下一页</v-btn>
						</template>
						<v-spacer></v-spacer>
						<v-btn
							color="error"
							text
							@click="assignWorker = false"
						>
							关闭
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</v-card>
		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
		<!--- loading overlay ---->
		<v-overlay :value="overlay">
			<v-progress-circular
			:size="70"
			:width="7"
			color="primary"
			indeterminate
			></v-progress-circular>
		</v-overlay>
		</div>
</template>

<script>

import WorkTeamService from '../../services/WorkTeam';
import WorkerService from '../../services/Worker';

export default {
	name: 'teamForm',
	data: () => ({
		valid: true,
		name: '',
		nameRules: [
			v => !!v || '请输入团队名称',
			v => (v && v.length >= 2) || '项目名称至少2个字',
		],
		requiredRules: [
			v => !!v || '必填内容',
		],
		teamType: null,
		teamTypes: [
			{ value: 'team', text: '团队' },
			{ value: 'individual', text: '个人' }],
		team_id: null,
		workers: [],
		availableWorkers: [],
		assignWorker: false,
		currentPage: 1,
		totalPages: 1,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		overlay: false,
	}),
	props: {
		teamId: {
			type: Number,
			required: false,
			default: null
		}
	},
	watch: {
		teamId(){
			this.getWorkTeam();
		},
		currentPage(){
			this.getWorkers();
		}
	},
	computed: {
		submitText(){
			return this.teamId ? '更新' : '添加';
		},
		nextPageBtnState(){
			return this.currentPage < this.totalPages ? false : true;
		},
		prevPageBtnState(){
			return this.currentPage > 1 ? false : true;
		}
	},
	created(){
		if(this.teamId){
			this.getWorkTeam();
		}
	},
	methods: {
		validate () {
			this.$refs.form.validate();
		},
		reset () {
			this.$refs.form.reset()
		},
		resetValidation () {
			this.$refs.form.resetValidation()
		},
		onFormSubmit(){
			this.overlay = true;
			//create paylod body here
			const body = {
				"name": this.name,
				"type": this.teamType
			}

			if(this.teamId){
				let workerArray =this.workers.map(worker=>{
					return worker.id
				})
				body.workers = workerArray;
				this.updateWorkTeam(body);
			} else {
				this.careateWorkTeam(body);
			}
		},
		async getWorkers(){
			try {
				let response = await WorkerService.getWorkerList(this.currentPage, 10, 0)
				this.availableWorkers = response.data.data.items;
				this.totalPages = response.data.data.total_pages;
				this.currentPage = response.data.data.current_page;
			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}
		},
		async getWorkTeam(){
			try {
				let response = await WorkTeamService.getWorkTeam(this.teamId);
					if(response.data.statusCode === 200){
						const teamData = response.data.data;
						this.name = teamData.name;
						this.teamType = teamData.type;
						this.team_id = teamData.id;
						this.workers = teamData.workers;
					} 
				} catch(error){
				this.snackbar.message = `错误信息：${error.data.message}`;
				this.snackbar.color = 'error';
				this.overlay = false;
				this.snackbar.model = true;
			}
		},
		async updateWorkTeam(body){
			try {
				let response = await WorkTeamService.updateWorkTeam(this.team_id, body);

				if(response.data.statusCode === 200){
					this.snackbar.message = `团队${response.data.message}`;
					this.snackbar.color = 'success';
					this.overlay = false;
					this.snackbar.model = true;
					setTimeout(()=>{
						this.snackbar.model = false;
						this.$emit('on-submit-success');
					}, 1000)
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					this.overlay = false;
					this.snackbar.model = true;
			}
		},
		async careateWorkTeam(body){
			try {
				let response = await WorkTeamService.careateWorkTeam(body);

				if(response.data.statusCode === 200){
					this.snackbar.message = `团队${response.data.message}`;
					this.snackbar.color = 'success';
					this.overlay = false;
					this.snackbar.model = true;
					setTimeout(()=>{
						this.snackbar.model = false;
						this.reset();
						this.$emit('on-submit-success');
					}, 1000)
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					this.overlay = false;
					this.snackbar.model = true;
			}
		},
		assignWorkersToTeam(){
			this.assignWorker = true;
			this.getWorkers();
		},
		removeWorker(targetWorker){
			this.workers.forEach( (worker, index)=>{
				if(worker.id === targetWorker.id){
					this.workers.splice(index, 1);
				}
			});
			this.onFormSubmit()
			
		},
		isWorkerDisabled(workerId){
			let returnVal = false;
			this.workers.forEach( (worker)=>{
				if(worker.id === workerId){
					returnVal = true;
				}
			});
			return returnVal;
		},
		nextPage(){
			if(this.currentPage < this.totalPages){
				this.currentPage ++;
			}
		},
		previousPage(){
			if(this.currentPage > 1){
				this.currentPage --;
			}
		},
		assignThisWorker(worker){
			this.workers.push(worker);
		},
	}
}
</script>
